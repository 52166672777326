const attraction = {
  'campaign-sidebar': false,
  contacts: false,
  'campaign-automation': false,
  'campaign-dispatches': false,
  broadcast: false,
  notifications: false,
  builder: false,
  'broadcast-social': false,
  'broadcast-monitoring': false,
  'broadcast-base': false,
  'social-monitoring': false,
  'conversion-monitoring-v2': false,
  'conversion-reports-v2': false,
}

const conversion = {
  'conversion-inbox': false,
  'conversion-inbox-sidebar': false,
  'conversion-settings': false,
  'conversion-activities': false,
  'conversion-integrations': false,
  'conversion-reports': false,
}

const catalogs = {
  catalogs: false,
}

const components = {
  components: false,
  'web-components': false,
  'web-components-vue': false,
}

const service = {
  'service-app': false,
  'chat-app': false,
}

const nutrir = {
  'nutrir-automation': false,
  'nutrir-automation-list': false,
  'nutrir-success-monitor': false,
}
const microjourney = {
  microjourney: false,
}

export default {
  authentication: false,
  'channel-messenger': false,
  'channel-sms': false,
  'channel-voice': false,
  'channel-whatsapp': false,
  templates: false,
  'channel-webchat': false,
  'channel-rcs': false,
  'customer-area': false,
  eventbus: false,
  'feature-toggle': false,
  home: false,
  'logs-api': false,
  selfservice: false,
  flows: false,
  organizations: false,
  'channel-instagram': false,
  'platform-report': false,
  labs: false,
  'platform-errors-page': false,
  'home-v2': false,
  'navbar-v2': false,
  'feedback-service': false,
  'channel-v2': false,
  'customer-data-service': false,
  'nlu-lite': false,
  masterbots: false,
  'nlu-commons': false,
  'zsuite-sidebar': false,
  'zsuite-navbar': false,
  ...attraction,
  ...components,
  ...conversion,
  'zsuite-home': false,
  'zsuite-onboarding': false,
  ...service,
  'zsuite-marketplace': false,
  'zsuite-reports': false,
  ...nutrir,
  ...catalogs,
  ...microjourney,
}
